@import "./fa-all.min.css";
@import "./fa-sharp-solid.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* cabin-regular - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-500 - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-600 - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-700 - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-italic - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: italic;
    font-weight: 400;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-500italic - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: italic;
    font-weight: 500;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-600italic - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: italic;
    font-weight: 600;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cabin-700italic - latin-ext_latin */
@font-face {
    font-family: 'Cabin';
    font-style: italic;
    font-weight: 700;
    src: local(''),
    url('../fonts/cabin-v26-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/cabin-v26-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* courgette-regular - latin-ext_latin */
@font-face {
    font-family: 'Courgette';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/courgette-v13-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('../fonts/courgette-v13-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.section {
    @apply w-full p-6;
}

.section-title {
    @apply w-48 mx-auto pt-6 border-b-2 border-orange-500 text-center text-2xl text-blue-700;
}

.section-subtitle {
    @apply p-2 text-center text-lg text-gray-700;
}

.-skewx-20 {
    transform: skewX(-20deg);
}

.card {
    @apply w-48 m-4 py-2 bg-white shadow-lg;
}

.card-image {
    @apply w-24 h-24 mx-auto rounded-full;
}

.card-title {
    @apply mx-2 mt-2 text-center text-lg text-gray-700 font-semibold;
}

.card-subtitle {
    @apply text-center text-gray-600;
}

.card-icons {
    @apply flex justify-center items-center mt-2;
}

.card-icon {
    @apply mx-1 text-blue-500 cursor-pointer;
}

.card-icon:hover {
    @apply text-orange-500;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-heading text-complementary-900 font-bold tracking-tight mb-2;
}

h1 {
    @apply text-4xl;
}

/*
 * See: https://blog.logrocket.com/responsive-image-gallery-css-flexbox/
 */
.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-gallery > li {
    height: 125px;
    cursor: pointer;
    position: relative;
    flex-grow: 1;
}

.image-gallery li img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 5px;
}
.image-gallery::after {
    content: "";
    flex-grow: 999;
}
